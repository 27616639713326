import { NavBar } from "components/laylouts/nav-bar/NavBar";
import TextButton from "components/widgets/text-Button/TextButton";
import React, { useCallback, useEffect, useState } from "react";
import { Table } from "components/laylouts/table/Table";
import {
  getSecretKeyPopupMode,
  setPopupMode,
} from "redux/sceret-key-popup-visibility/secret-key-popup-visibility-slice";
import { useDispatch, useSelector } from "react-redux";
import { Strings } from "redux/sceret-key-popup-visibility/secret-key-popup-visibility-static";
import AddSecretKey from "components/laylouts/manage-secret-key/add-secret-key/AddSecretKey";
import VerifySecretKey from "components/laylouts/manage-secret-key/verify-secret-key/Verifysecretkey";
import RejectReasonModal from "components/laylouts/reject-reason-modal/RejectReasonModal";
import {
  deleteQuestion,
  fetchQuestionDetailByInstance,
  getSelectedQuestion,
  getmanageQuestionState,
  setFlyoutMode,
  updateStatus,
} from "redux/manage-question/manage-question-slice";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  fetchAuthorList,
  fetchCategories,
  fetchDifficultyLevels,
  fetchLanguages,
  fetchQuestionCreationStatusList,
  fetchSubjects,
  fetchTypes,
  getAddQuestionState,
  getAuthorList,
} from "redux/add-question/add-question-slice";
import { reqParams } from "views/manage-questions/question-initial-state";
import Loader from "components/widgets/loader/Loader";
import {
  notifyError,
  notifySuccess,
  hasAddEditAccess,
  hasTranslationAccess,
  findValueFromList,
  getStorageItem,
} from "utils/utility";
import { passageDetail } from "redux/passage/passage-slice";
import { ROUTES_CONSTANTS } from "routes/route-constants";
import { ConfirmationModal } from "components/widgets/delete-modal/ConfirmationModal";
import moment from "moment";
import "./QuestionsDetails.css";
import { globalInstanceData } from "redux/profile/profile-slice";
import { accessRoleMapView, successStatusCodes } from "utils/constants/common";
import { renderQuestionCreationStatus } from "views/manage-questions/manage-questions-listing/ManageQuestionsListingUtils";
import QuestionDetailContainer from "./QuestionDetailContainer";
import QuestionDetailsFooter from "./QuestionDetailsFooter";
import { BtnTitles } from "utils/constants/constants";

const QuestionsDetails = () => {
  const selectedInstanceData = useSelector(globalInstanceData);
  const popupMode = useSelector(getSecretKeyPopupMode);
  const onlineMastersList = useSelector(getAddQuestionState);
  const selectedOnlineQuestionDetail = useSelector(getSelectedQuestion);
  const manageQuestionState = useSelector(getmanageQuestionState);
  const onlineAuthorsList = useSelector(getAuthorList);
  const [showHistory, setShowHistory] = useState(false);
  const [loader, setLoader] = useState(false);
  const [languagesOptionsList, setLanguagesOptionsList] = useState([]);
  const [languageWiseData, setLanguageWiseData] = useState({});
  const [actionsIds, setActionIds] = useState([]);
  const [statusToUpdate, setStatusToUpdate] = useState({});
  const [statusParams, setStatusParams] = useState({});
  const [questionParams, setQuestionParams] = useState({
    instanceId: selectedInstanceData ? selectedInstanceData?._id : undefined,
    page: 1,
    perPage: 10,
    isInitialCall: true,
    search: "",
  });
  const selectedData = selectedOnlineQuestionDetail;
  const [selectedLanguage, setSelectedLanguage] = useState(
    selectedData?.language ? selectedData?.language : "English"
  );
  const selectedActions = getStorageItem("selectedActions")
    ? getStorageItem("selectedActions")
    : [];
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  let { flyoutMode } = manageQuestionState;

  const authorList = onlineAuthorsList;
  const typesList = onlineMastersList?.typesList;
  const levelsList = onlineMastersList?.levelsList;
  const subjectsList = onlineMastersList?.subjectsList;
  const categoriesList = onlineMastersList?.categoriesList;

  useEffect(() => {
    fetchRequiredData();
  }, []);

  useEffect(() => {
    getQuestionDetailByInstanceId(location);
  }, [location]);

  useEffect(() => {
    if (selectedData?.isGroupPassage) {
      dispatch(passageDetail({ passageId: selectedData?.groupPassageId }));
    }
    if (selectedData) {
      setSelectedLanguage(selectedData?.language);
    }
    let languageListArray = [];
    let languageWiseList = {};
    languageListArray.push(selectedData?.language);
    languageWiseList[selectedData?.language] = {
      questionDescription: selectedData?.questionDescription,
      questionImage: selectedData?.questionImage,
      answerOptions: selectedData?.answerOptions,
      type: selectedData?.type,
    };
    if (selectedData?.isGroupPassage) {
      languageWiseList[selectedData?.language]["passageDetails"] = {
        passage: selectedData?.groupPassage?.passage || "",
        image: selectedData?.groupPassage?.image?.value || "",
      };
    }
    if (selectedData?.languageTranslate) {
      Object.keys(selectedData?.languageTranslate).map((language) => {
        languageListArray.push(language);
        const translatedObj = selectedData?.languageTranslate?.[language];
        const translatedGroupObj =
          selectedData?.groupPassage?.languageTranslate?.[language];

        languageWiseList[language] = {
          questionDescription: translatedObj?.questionDescription,
          answerOptions: translatedObj?.answerOptions || translatedObj?.answer,
          type: selectedData?.type,
          questionImage: translatedObj?.questionImage,
        };
        if (selectedData?.isGroupPassage) {
          languageWiseList[language]["passageDetails"] = {
            passage: translatedGroupObj?.passage || "",
            image: translatedGroupObj?.value || "",
          };
        }
      });
    }
    setLanguagesOptionsList(languageListArray);
    setLanguageWiseData(languageWiseList);
    setSelectedLanguage(selectedData?.language);
  }, [selectedData]);

  const fetchRequiredData = () => {
    dispatch(fetchLanguages(reqParams)).then((res) => {
      handleError(res, 201);
    });
    dispatch(fetchCategories(reqParams)).then((res) => {
      handleError(res, 201);
    });
    dispatch(fetchSubjects(reqParams)).then((res) => {
      handleError(res, 201);
    });
    dispatch(fetchTypes()).then((res) => {
      handleError(res, 200);
    });
    dispatch(fetchDifficultyLevels()).then((res) => {
      handleError(res, 200);
    });
    dispatch(fetchQuestionCreationStatusList()).then((res) => {
      handleError(res, 200);
    });
    const authorParams = {
      access: accessRoleMapView?.Web,
      instanceId: selectedInstanceData?._id,
    };
    dispatch(fetchAuthorList(authorParams)).then((res) => {
      handleError(res, 201);
    });
  };

  const onChangeParams = useCallback((updatedParam) => {
    setQuestionParams((prevState) => ({
      ...prevState,
      ...updatedParam,
    }));
  });

  const columns = [
    {
      field: "srNo",
      header: "sr No",
      shouldRenderElement: true,
      renderElement: (ele, index, data) => {
        return (
          <span className="fs-14 column_data question-description">
            {index + 1}
          </span>
        );
      },
    },
    { field: "version", header: "version" },
    {
      field: "createdAt",
      header: "date And Time",
      shouldRenderElement: true,
      renderElement: (ele, index, data) => {
        return (
          <span className="fs-14 column_data mr-6">
            {moment(data?.createdAt).format("DD-MM-YYYY HH:mm:ss A")}
          </span>
        );
      },
    },
    {
      field: "authorId",
      header: "user",
      shouldRenderElement: true,
      renderElement: (ele, index, data) => {
        const authorName = getAuthorNameById(data?.authorId);
        return <span className="fs-14 column_data mr-6">{authorName}</span>;
      },
    },
    {
      field: "event",
      header: "action Taken",
      shouldRenderElement: true,
      renderElement: (ele, index, data) => {
        const statusEventName = renderQuestionCreationStatus(data?.event);
        return (
          <span className="fs-14 column_data mr-4">{statusEventName}</span>
        );
      },
    },
  ];

  const getQuestionDetailByInstanceId = (location) => {
    const optionToSend = {
      instanceId: selectedInstanceData?._id,
      id: location?.state?.id,
    };
    setLoader(true);
    dispatch(fetchQuestionDetailByInstance(optionToSend))
      .then(() => {})
      .catch((error) => {
        notifyError(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleError = (res, statusCode) => {
    if (res?.payload?.statusCode !== statusCode) {
      notifyError(res?.payload?.data?.message);
    }
  };

  const getAuthorNameById = (authorId) => {
    let name = null;
    authorList?.length > 0 &&
      authorList?.forEach((d) => {
        if (authorId === d?.authorId) {
          name = `${d?.autherFirstName} ${d?.authorLastName}`;
        }
      });
    return name;
  };

  const onlanguageChange = (e) => {
    if (e?.target?.value) {
      setSelectedLanguage(e?.target?.value);
    }
  };

  const renderDetails = (name, value) => {
    return (
      <div>
        <span>{name} : </span>
        <b className="text_color_primary">{value}</b>
      </div>
    );
  };

  const removeHtmlContent = (data) => {
    var regex = /(&nbsp;|<([^>]+)>)/gi;
    var body = data ? data?.toString() : "";
    var result = body?.replace(regex, "");
    return result;
  };

  const optionsNameData =
    selectedData?.answerOptions?.length > 0 &&
    selectedData?.answerOptions?.map((d) => {
      return removeHtmlContent(d?.optionName);
    });

  const closeSecretKeyPopup = (e) => {
    dispatch(setPopupMode(""));
  };

  const onIconDelete = (e, val, id) => {
    if (selectedData?.isEditDisable) {
      return;
    }
    e?.preventDefault();
    dispatch(setFlyoutMode({ mode: val, id: id }));
  };

  const onCancelDeleteHandler = () => {
    dispatch(setFlyoutMode({ mode: "" }));
  };

  const onConfirmDeleteHandler = async () => {
    const options = {
      id: location?.state?.id,
      instanceId: selectedInstanceData?._id,
    };
    setLoader(true);
    dispatch(deleteQuestion(options))
      .then((res) => {
        if (successStatusCodes.includes(res?.payload?.statusCode)) {
          notifySuccess(res?.payload?.message);
          onCancelDeleteHandler();
          history.push(ROUTES_CONSTANTS?.home);
        } else {
          notifyError(res?.payload?.data?.message);
        }
      })
      .catch((error) => {
        notifyError(error?.message);
      })
      .finally(() => {
        setLoader(false);
        onCancelDeleteHandler();
      });
  };

  const onIconEditEvent = (e, val, id) => {
    e?.preventDefault();
    dispatch(setFlyoutMode({ mode: val, id: id }));
    history.push({
      pathname: ROUTES_CONSTANTS.addQuestion,
      state: { mode: val, id: id, item: selectedData },
    });
  };

  const onIconEditHandler = (e) => {
    if (selectedData?.isEditDisable) {
      return;
    }
    onIconEditEvent(e, "Edit", location?.state?.id);
  };

  const pageToSend = location?.state?.prevPath
    ? location?.state?.prevPath
    : location?.pathname;

  const updateStatusCall = (optionsToSend) => {
    const options = { ...statusParams, ...optionsToSend };
    if (options?.actionsIds?.length === 0) {
      notifyError("Please select atleast one question to update status");
      return;
    }
    dispatch(updateStatus(options))
      .then((res) => {
        if (successStatusCodes.includes(res?.payload?.statusCode)) {
          notifySuccess(res?.payload?.data?.message);
          setActionIds([]);
          history.push(pageToSend);
        } else {
          notifyError(res?.payload?.data?.message);
        }
      })
      .catch((error) => notifyError(error.message))
      .finally(() => closeSecretKeyPopup());
  };

  const onActionStatusHandler = (e, actionData, single = true) => {
    const option = selectedActions?.find((x) => x.label === actionData?.label);
    if (single) setActionIds([selectedData?._id]);
    if (option) {
      let statusOptions = {
        status: option?.status,
      };
      const value = option?.requestor;
      if (value) {
        statusOptions[value] = true;
      }
      setStatusToUpdate(statusOptions);
      if (option?.showKeyPopup) {
        if (option?.label.includes("Reject")) {
          setStatusParams({
            status: option?.status,
            instanceId: selectedInstanceData?._id,
            questionIds: single ? [selectedData?._id] : [],
          });
          dispatch(setPopupMode(Strings.POPUP_MODE_REJECT));
        } else {
          dispatch(setPopupMode(Strings.POPUP_MODE_ADD));
        }
      } else {
        const optionsToSend = {
          requestName: actionData?.requestName,
          instanceId: selectedInstanceData?._id,
          questionIds: single ? [selectedData?._id] : [],
          ...statusOptions,
        };
        updateStatusCall(optionsToSend);
      }
    }
  };

  const shouldDisableAction = (ele) =>
    ele?.label === Strings.USER_TYPE_REVIEWER &&
    selectedData?.status !== "PENDING" &&
    selectedData?.status !== "LANGUAGE_REJECTED" &&
    selectedData?.status !== "REJECTED";

  if (loader) return <Loader />;
  else
    return (
      <>
        <NavBar title={Strings.NAVBAR_TITLE_QUESTION_DETAILS} isBack={true} />
        <div className="question-detail-container">
          <div className="d-flex justify-content-between px-5 py-3">
            <div>
              {renderDetails(
                "Questions Id",
                selectedData?.questionId && !selectedData?.isAdd
                  ? selectedData?.questionId
                  : "-"
              )}
              {renderDetails(
                "Subject",
                findValueFromList(
                  subjectsList,
                  selectedData?.subject,
                  "subjectCode",
                  "subjectName"
                )
              )}
              {renderDetails(
                "Category",
                findValueFromList(
                  categoriesList,
                  selectedData?.category,
                  "categoryCode",
                  "categoryName"
                )
              )}
            </div>
            <div>
              {renderDetails(
                "Type",
                findValueFromList(
                  typesList,
                  selectedData?.type,
                  "typeCode",
                  "typeName"
                )
              )}
              {renderDetails(
                "Difficulty Level",
                findValueFromList(
                  levelsList,
                  selectedData?.level,
                  "difficultyCode",
                  "difficultyLevel"
                )
              )}
            </div>
            <div>
              {renderDetails(
                "Group Question Title",
                selectedData?.groupPassage?.title
                  ? selectedData?.groupPassage?.title
                  : "No Passage"
              )}
              {renderDetails("Mark", selectedData?.marks)}
              {renderDetails("Negative Marking", selectedData?.negativeMark)}
            </div>
            <div>
              {renderDetails("Duration", selectedData?.duration + " Mins")}
              {renderDetails("Language", selectedData?.language)}
            </div>
          </div>
          <hr className="horizontal_line" />
          <div className="px-5 d-flex justify-content-end flex-row">
            {!showHistory ? (
              <TextButton
                title={BtnTitles.VIEW_HISTORY}
                rounded={false}
                clickHandler={() => setShowHistory(true)}
              />
            ) : null}
            {showHistory ? (
              <TextButton
                title={BtnTitles.CANCEL}
                rounded={false}
                className="mx-2"
                clickHandler={() => setShowHistory(false)}
              />
            ) : null}
          </div>
          <div className="my-2">
            {showHistory ? (
              <Table
                data={selectedData?.historyEvent ?? []}
                columns={columns}
                isFromDetailScreen={true}
              />
            ) : null}
            <QuestionDetailContainer
              showHistory={showHistory}
              selectedData={selectedData}
              languagesOptionsList={languagesOptionsList}
              onlanguageChange={onlanguageChange}
              selectedLanguage={selectedLanguage}
              optionsNameData={optionsNameData}
              languageWiseData={languageWiseData}
              removeHtmlContent={removeHtmlContent}
            />
          </div>
        </div>
        <QuestionDetailsFooter
          location={location}
          selectedActions={selectedActions}
          selectedData={selectedData}
          shouldDisableAction={shouldDisableAction}
          onActionStatusHandler={onActionStatusHandler}
          hasAddEditAccess={hasAddEditAccess}
          hasTranslationAccess={hasTranslationAccess}
          onIconEditHandler={onIconEditHandler}
          onIconDelete={onIconDelete}
        />
        <AddSecretKey
          secretKeyModal={popupMode === Strings.POPUP_MODE_ADD}
          secretKeyModalClose={closeSecretKeyPopup}
          closeSecretKeyPopup={(e) => closeSecretKeyPopup(e)}
          actionsIds={actionsIds}
          statusToUpdate={statusToUpdate}
          setActionIds={setActionIds}
          onChangeParams={onChangeParams}
          questionsParams={questionParams}
        />
        <VerifySecretKey
          examKeyPopupVisible={popupMode === Strings.POPUP_MODE_VERIFY}
          examKeyPopupClose={closeSecretKeyPopup}
          closeSecretKeyPopup={closeSecretKeyPopup}
        />
        <RejectReasonModal
          title={Strings.REJECTION_MODAL_TITLE}
          reasons={true}
          show={popupMode === Strings.POPUP_MODE_REJECT}
          closeSecretKeyPopup={closeSecretKeyPopup}
          onSubmit={updateStatusCall}
        />
        {flyoutMode === "Delete" ? (
          <ConfirmationModal
            title={Strings.CONFIRMATION_MSG}
            onCancel={onCancelDeleteHandler}
            confirmHandler={onConfirmDeleteHandler}
            btnText={BtnTitles.SUBMIT}
            show={true}
          />
        ) : null}
      </>
    );
};

export default QuestionsDetails;
